<template>
    <div class="control">
        <van-tabs v-model="active" color="#369867">
            <van-tab title="全部">
                <div class="content">
                    <div class="total-box" v-if="list.obj1.length > 0">
                        <span>全部分享订单</span>
                        <strong>{{ list.obj1.length }}</strong>
                    </div>
                    <van-list v-model="loading" finished-text="没有更多了" @load="getData" :finished="finished">
                        <div class="item" v-for="item in list.obj1" :key="item.id">
                            <div class="user-info">
                                <div class="flex">
                                    <van-image width="30" height="30" :src="item.headimgurl"/>
                                    <div class="user-name">{{ item.user_nickname }}</div>
                                </div>
                                <div class="user-time" v-if="item.pay_at">支付时间:{{ item.pay_at }}</div>
                            </div>
                            <div class="order-info">
                                <div class="good-banner">
                                    <van-image width="100%" height="100%" :src="item.good_pic" fit="cover"/>
                                </div>
                                <div class="good-info">
                                    <div class="good-title">
                                        {{ item.goods_title }}
                                    </div>
                                    <div class="price">
                                        ¥<strong>{{
                                            item.order_price.split(".")[0]
                                        }}</strong>.{{ item.order_price.split(".")[1] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <van-empty v-if="finished && list.length === 0"/>
                    </van-list>
                </div>
            </van-tab>
            <van-tab title="已支付">
                <div class="content">
                    <div class="total-box" v-if="list.obj3.length > 0">
                        <span>已支付分享订单</span>
                        <strong>{{ list.obj3.length }}</strong>
                    </div>
                    <van-list v-model="loading3" finished-text="没有更多了" @load="getData3" :finished="finished3">
                        <div class="item" v-for="item in list.obj3" :key="item.id">
                            <div class="user-info">
                                <div class="flex">
                                    <van-image width="30" height="30" :src="item.headimgurl"/>
                                    <div class="user-name">{{ item.user_nickname }}</div>
                                </div>
                                <div class="user-time" v-if="item.pay_at">支付时间:{{ item.pay_at }}</div>
                            </div>
                            <div class="order-info">
                                <div class="good-banner">
                                    <van-image width="100%" height="100%" :src="item.good_pic" fit="cover"/>
                                </div>
                                <div class="good-info">
                                    <div class="good-title">
                                        {{ item.goods_title }}
                                    </div>
                                    <div class="price">
                                        ¥<strong>{{
                                            item.order_price.split(".")[0]
                                        }}</strong>.{{ item.order_price.split(".")[1] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <van-empty v-if="finished3 && list.obj3.length === 0"/>
                    </van-list>
                </div>
            </van-tab>
            <van-tab title="待支付">
                <div class="content">
                    <div class="total-box" v-if="list.obj2.length > 0">
                        <span>待支付分享订单</span>
                        <strong>{{ list.obj2.length }}</strong>
                    </div>
                    <van-list v-model="loading2" finished-text="没有更多了" @load="getData2" :finished="finished2">
                        <div class="item" v-for="item in list.obj2" :key="item.id">
                            <div class="user-info">
                                <div class="flex">
                                    <van-image width="30" height="30" :src="item.headimgurl"/>
                                    <div class="user-name">{{ item.user_nickname }}</div>
                                </div>
                                <div class="user-time" v-if="item.pay_at">支付时间:{{ item.pay_at }}</div>
                            </div>
                            <div class="order-info">
                                <div class="good-banner">
                                    <van-image width="100%" height="100%" :src="item.good_pic" fit="cover"/>
                                </div>
                                <div class="good-info">
                                    <div class="good-title">
                                        {{ item.goods_title }}
                                    </div>
                                    <div class="price">
                                        ¥<strong>{{
                                            item.order_price.split(".")[0]
                                        }}</strong>.{{ item.order_price.split(".")[1] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <van-empty v-if="finished2 && list.obj2.length === 0"/>
                    </van-list>
                </div>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
export default {
    name: "userList",
    data() {
        return {
            list: {
                obj1: [],
                obj2: [],
                obj3: []
            },
            loading: false,
            finished: false,
            loading2: false,
            finished2: false,
            loading3: false,
            finished3: false,
            active: 0,
            page: 1,
            page2: 1,
            page3: 1
        }
    },
    methods: {
        getData() {
            axios.get("/qykshop/get_my_promote_order", {
                params: {
                    page: this.page,
                }
            }).then((res) => {
                if (res.code === 1) {
                    this.list.obj1.push(...res.data.list)
                    this.page++
                    this.loading = false
                    if (this.list.obj1.length >= res.data.total) {
                        this.finished = true
                    }
                }
            })
        },
        getData2() {
            axios.get("/qykshop/get_my_promote_order", {
                params: {
                    page: this.page2,
                    pay_status: 1
                }
            }).then((res) => {
                if (res.code === 1) {
                    this.list.obj2.push(...res.data.list)
                    this.page2++
                    this.loading2 = false
                    if (this.list.obj2.length >= res.data.total) {
                        this.finished2 = true
                    }
                }
            })
        },
        getData3() {
            axios.get("/qykshop/get_my_promote_order", {
                params: {
                    page: this.page3,
                    pay_status: 2
                }
            }).then((res) => {
                if (res.code === 1) {
                    this.list.obj3.push(...res.data.list)
                    this.page3++
                    this.loading3 = false
                    if (this.list.obj3.length >= res.data.total) {
                        this.finished3 = true
                    }
                }
            })
        },
    }
}
</script>

<style scoped lang="less">
.control {
    width: 100%;
    min-height: 100vh;
    background: #F8F8F8;
    box-sizing: border-box;

    /deep/ .van-tab--active {
        color: #369867 !important;
        font-weight: bold;
        font-size: 17px;
    }

    /deep/ .van-tab {
        font-size: 15px;
        color: #666666;
    }

    .content {
        padding: 10px 14px;
        box-sizing: border-box;
    }

    .total-box {
        margin-bottom: 15px;
        font-size: 14px;
        color: #b4b4b4;

        strong {
            color: #369867;
            margin-left: 5px;
        }
    }

    .item {
        background: #FFFFFF;
        border-radius: 6px;
        padding: 22px 14px;
        box-sizing: border-box;

        .user-info {
            padding-bottom: 9px;
            margin-bottom: 13px;
            border-bottom: 1px solid #F5F5F5;

            .van-image {
                border-radius: 50%;
                overflow: hidden;
                margin-right: 10px;
            }

            .user-name {
                font-size: 15px;
                font-weight: bold;
                color: #232323;
            }

            .user-time {
                font-size: 13px;
                font-weight: 500;
                color: #C7C8CA;
                width: fit-content;
                transform: translateX(40px);
            }
        }

        .order-info {
            display: flex;

            .good-banner {
                width: 30%;
                position: relative;
                padding-top: 22%;
                border-radius: 5px;
                overflow: hidden;

                .van-image {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
            }

            .good-info {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-left: 18px;

                .good-title {
                    font-size: 14px;
                    font-weight: bold;
                    color: #232323;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    -webkit-line-clamp: 3; /* 这里是超出几行省略 */
                    overflow: hidden;
                }

                .price {
                    font-weight: 500;
                    color: #ED2525;
                    font-size: 12px;

                    strong {
                        font-size: 21px;
                    }
                }
            }
        }

        & + .item {
            margin-top: 10px;
        }
    }
}
</style>
import { render, staticRenderFns } from "./userOrderList.vue?vue&type=template&id=1953813e&scoped=true&"
import script from "./userOrderList.vue?vue&type=script&lang=js&"
export * from "./userOrderList.vue?vue&type=script&lang=js&"
import style0 from "./userOrderList.vue?vue&type=style&index=0&id=1953813e&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1953813e",
  null
  
)

export default component.exports